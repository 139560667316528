.primary-form {
    .form-group {
        label {
            letter-spacing: ls(25);
        }

        input {
            background: color("bravo");
            color: color("white");
            border-color: color("bravo");
            border-radius: 2px;
            box-shadow: none;
            padding: 15px 20px;
            line-height: 1.3125;
            letter-spacing: ls(25);
            transition: border-color 0.3s ease;

            &::placeholder {
                color: rgba(color("white"), 50%);
            }

            &.has-error {
                border-color: color("foxtrot");
            }
        }
    }
}

.password-wrapper {
    position: relative;

    .password-visibility-btn {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        padding: 0;
        border: none;
        background: none;
        border-radius: 0;

        &:focus {
            background: none;
        }

        img {
            width: 26.99px;
            height: 20.24px;
        }
    }
}

.error-text {
    color: color("foxtrot");
    margin: 0;
    
    @extend .tif-font-sm;
}

.secondary-form {
    .form-group {
        label {
            margin-bottom: 35px;
            color: color("white");
        }

        input,
        textarea,
        select {
            border-radius: 0;
            background: none;
            border: none;
            box-shadow: none;
            font-size: rem-calc(30px);
            padding: 0 0 15px;
            border-bottom: 1px solid color("golf");
            color: color("hotel");
            transition: border-color 0.3s ease-in-out;
            line-height: 1.3;

            &::placeholder {
                color: color("golf");
            }

            &.has-error {
                border-color: color("foxtrot");
            }

            &.multiline {
                min-height: 155px;
            }

            &.small {
                font-size: rem-calc(20px);
                color: rgba(color("white"), 80%);
                border-color: color("white");

                &::placeholder {
                    color: rgba(color("white"), 30%);
                }
            }
        }

        .password-wrapper {
            input {
                padding-right: 87px;
            }

            .password-visibility-btn {
                top: 15px;
                right: 30px;
            }
        }
    }
}

.light-mode {
    .secondary-form {
        .form-group {
            label {
                color: color("oscar");
            }

            input,
            textarea,
            select {
                border-bottom: 1px solid color("quebec");
                color: color("november");

                &::placeholder {
                    color: color("quebec");
                }

                &.has-error {
                    border-color: color("oscar");
                }
            }
        }
    }
}