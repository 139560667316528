$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px,
  xxl: 1600px
);

// PADDING & MARGIN CLASSES
$pm_start: 0;
$pm_end: 220;
$pm_offset: 5;
$pm_unit: "px";

// GUTTER CLASSES
$g_start: 0;
$g_end: 70;
$g_offset: 5;
$g_unit: "px";

// Colors
$colors: (
  "white": #fff,
  "black": #000,
  "alpha": #121212,
  "bravo": #404040,
  "charlie": #3b61ce,
  "delta": #181818,
  "echo": #00ab79,
  "foxtrot": #e1125b,
  "golf": #383c3a,
  "hotel": #b3b3b3,
  "india": #282828,
  "juliet": #aeadb3,
  "kilo": #8b8892,
  "lima": #f2f2f2,
  "mike": #d9d9d9,
  "november": #24439c,
  "oscar": #98a9b3,
  "papa": #f1f4f6,
  "quebec": #d8dfe3,
  "romeo": #2a2a2a,
  "sierra": #707070,
  "tango": #ab0000,
  "uniform": #c92a2a,
  "victor": #828282,
);

// Font family
$primary-font: "Roboto", sans-serif;
