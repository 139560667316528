.correspondence {
    border-radius: 10px;
    position: relative;

    p {
        font-size: 20px;
        line-height: 1.5;

        &.date {
            font-size: 14px;
            position: absolute;
            top: 25px;
            right: 30px;
        }
    }
}