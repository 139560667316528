html {
    &[data-mantine-color-scheme="dark"] {
        .wrapper {
            background: var(--mantine-color-dark-6);
        }   
    }

    &[data-mantine-color-scheme="light"] {
        .wrapper {
            background: var(--mantine-color-gray-1);
        }   
    }
}

body {
    font-family: $primary-font !important;
}

p {
    @extend .tif-font-md;
}

h1,
.tif-h1,
.tif-font-xl {
    font-size: clamp(rem-calc(24px), 2.238vw, rem-calc(32px)) !important;
}

h2,
.tif-h2,
.tif-font-lg {
    font-size: clamp(rem-calc(18px), 1.667vw, rem-calc(24px));
}

h3,
.tif-h3,
.tif-font-md {
    font-size: clamp(rem-calc(14px), 1.119vw, rem-calc(16px));
}

.tif-font-sm {
    font-size: clamp(rem-calc(12px), 1.119vw, rem-calc(14px));
}

.tif-font-xs {
    font-size: clamp(rem-calc(10px), 0.839vw, rem-calc(12px));
}

.tif-ls-50 {
    letter-spacing: ls(50);
}

#page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
}

#content-wrapper {
    display: flex;
    position: static;
    flex-wrap: nowrap;
    flex: 1 0 0%;
    overflow: hidden;

    &.scrollable {
        #main-content {
            display: flex;
            flex-direction: column;
            flex: 1 0 0%;
            overflow: hidden;

            .wrapper {
                flex: 1 0 0%;
                overflow: auto;
                
                .table-wrapper {
                    min-width: 991px;
                }
            }
        }
    }
}

#main-content {
    max-width: calc(100vw - 80px);

    .back-wrapper {
        padding: 30px 40px;
    }

    .wrapper {
        padding: 35px 40px 115px;
        height: 100%;

        @media (max-width: 991px) {
            padding: 20px 15px 55px;
        }
    }

    @media (max-width: 991px) {
        max-width: 100vw;
    }
}

.visually-hidden {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(color("uniform"), 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(color("uniform"), 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(color("uniform"), 0);
	}
}

.pulse-animation {
    box-shadow: 0 0 0 0 rgba(color("uniform"), 100%);
    transform: scale(1);
    animation: pulse 2s infinite;
}

.truncate-wrapper {
    flex: 1;
    min-width: 0;
}

.cursor-pointer {
    cursor: pointer;
}