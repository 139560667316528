html {
    &[data-mantine-color-scheme="light"] {
        .pto-calendar {
            .month-users-time-off {
                .user-pto-counts-wrapper {
                    .user-pto-count {
                        background: color("black");
                    }
                }
            }
        }
    }

    &[data-mantine-color-scheme="dark"] {
        .pto-calendar {
            .month-users-time-off {
                .user-pto-counts-wrapper {
                    .user-pto-count {
                        background: color("white");
                    }
                }
            }
        }
    }
}

.pto-calendar {
    > div {
        max-width: 32px;
    }

    .month-users-time-off {
        .mantine-Badge-root {
            cursor: pointer;
    
            &:hover {
                background: var(--mantine-color-blue-7);
            }
        }
    
        .user-pto-counts-wrapper {
            max-width: 22px;
            margin: auto;

            .user-pto-count {
                width: 3px;
                height: 3px;
                background: color("white");
                border-radius: 3px;
            }
        }
    }
}