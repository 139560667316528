html {
  &[data-mantine-color-scheme="dark"] {
    .stat-panel {
      background-image: linear-gradient(-60deg,
      var(--mantine-color-dark-5) 0%,
      var(--mantine-color-dark-7) 100%);

      .stat-count,
      .stat-title {
        color: var(--mantine-color-text) !important;
      }
    }

    .stat-container:not(:first-child) {
      border-left: 1px solid var(--mantine-color-dark-3);
    }
  }

  &[data-mantine-color-scheme="light"] {
    .stat-panel {
      background-image: linear-gradient(-60deg,
      var(--mantine-color-gray-1) 0%,
      var(--mantine-color-gray-3) 100%);
    }

    .stat-container:not(:first-child) {
      border-left: 1px solid var(--mantine-color-gray-5);
    }
  }
}

.value {
  line-height: 1 !important;
}

.diff {
  line-height: 1;
  display: flex;
  align-items: center;
}

.icon {
  color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
}

.title {
  font-weight: 700;
  text-transform: uppercase;
}

.card {
  background-color: var(--mantine-color-body);
  color: var(--mantine-text-color);
}

.title {
  line-height: 1;
}

.stat-panel {
  display: flex;
  padding: calc(var(--mantine-spacing-xl) * 1.5);
  border-radius: var(--mantine-radius-md);
}

.stat-title {
  text-transform: uppercase !important;
  font-weight: 700 !important;
  font-size: var(--mantine-font-size-sm) !important;
}

.stat-count {
  font-size: 32px !important;
  line-height: 1 !important;
  font-weight: 700 !important;
  margin-bottom: var(--mantine-spacing-md) !important;
}

.stat-container {
  flex: 1;

  &+& {
    padding-left: var(--mantine-spacing-xl);
    margin-left: var(--mantine-spacing-xl);
  }
}

.ticket-count-stats {
  .progressLabel {
    line-height: 1;
    font-size: var(--mantine-font-size-sm);
  }
  
  .stat {
    border-bottom: 3px solid;
    padding-bottom: 5px;
  }
  
  .statCount {
    line-height: 1.3;
  }
  
  .diff {
    display: flex;
    align-items: center;
  }
  
  .icon {
    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
  }
}

.user-accordion-control {
  background-color: var(--item-filled-color) !important;
  top: -35px;
  z-index: 9;
}