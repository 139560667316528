#top-bar {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    border-bottom: 1px solid color("bravo");
    z-index: 9;

    @media (max-width: 768px) {
        display: block;
        justify-content: center;
    }

    .title-holder {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        h1 {
            font-weight: 300;
        }

        @media (max-width: 768px) {
            margin-bottom: 20px;
            justify-content: center;
        }
    }

    .user-holder {
        flex-shrink: 0;
        margin-left: auto;

        @media (max-width: 768px) {
            text-align: center;
        }

        p {
            text-transform: uppercase;
        }
    }

    .search-holder {
        position: relative;
        width: 100%;
        max-width: 346px;
        margin: 0 40px;
        flex: 1 1 0;

        form {
            width: 100%;
        }

        .input-holder {
            z-index: 2;

            input {
                width: 100%;
                border: none;
                border-radius: 20px;

                &:focus-visible {
                    outline: none;
                }

                &::placeholder {
                    color: color("hotel");
                    font-style: italic;
                }
            }

            img {
                position: absolute;
                left: 18.5px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .search-results {
            background: color("alpha");
            top: -10px;
            left: -10px;
            width: calc(100% + 20px);
            border-radius: 20px;
            padding: 71px 20px 20px;
            border: 1px solid color("india");
            z-index: 1;

            p {
                color: color("white");
            }

            ul {
                li {
                    border-bottom: 1px solid color("india");
                    cursor: pointer;

                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    .result {
                        max-width: calc(100% - 16px);

                        p {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;

                            &.question {
                                color: color("white");
                            }

                            &.response {
                                color: color("hotel");
                            }
                        }
                    }

                    img {
                        opacity: 0;
                        transition: opacity 0.3s ease-in-out;
                    }

                    &:hover {
                        .result {
                            p {
                                color: color("echo");
                            }
                        }

                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            width: 300px;
            margin: 0 auto 20px;
        }
    }
}

.light-mode {
    #top-bar {
        border-bottom: 1px solid color("quebec");

        .search-holder {
            input {
                &::placeholder {
                    color: color("oscar");
                }
            }

            .search-results {
                background: color("white");
                border-color: color("quebec");

                p {
                    color: color("november");
                }

                ul {
                    li {
                        border-color: color("quebec");
                        
                        .result {
                            p {
                                &.question {
                                    color: color("november");
                                }
                            }

                            &:hover {
                                p {
                                    color: color("echo");
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}