.sidebar {
    width: 80px;
    height: 100vh;
    padding: var(--mantine-spacing-md);
    display: flex;
    flex-direction: column;
    border-right: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));

    .sidebar-header {
        img {
            height: 30px;
        }
    }

    .sidebar-main {
        flex: 1;
        margin-top: 50px;
    }

    .link {
        width: 50px;
        height: 50px;
        border-radius: var(--mantine-radius-md);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.link {
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));

    &:hover {
        background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
    }

    &[data-active="true"] {
        &,
        &:hover {
            background-color: var(--mantine-color-blue-light);
            color: var(--mantine-color-blue-light-color);
        }
    }
}

.client-sidebar {
    width: 450px;
    min-width: 450px;

    a {
        color: var(--mantine-color-blue-5);
        text-decoration: none;
    
        &:hover {
            text-decoration: underline;
        }
    }
}

.sidebar-menu-dropdown {
    min-width: 175px;
}