.sortable-table {
    .mantine-Table-th {
        padding: 0;
    }
    
    .control {
        width: 100%;
        padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    
        &:hover {
            background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
        }
    }
    
    .icon {
        width: 21px;
        height: 21px;
        border-radius: 21px;
    }
}

table {
    tbody {
        tr {
            td {
                &.truncate {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    max-width: 1px;
                }
            }
        }
    }
}