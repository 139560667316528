.progress-bar-wrapper {
    position: relative;
    border: 1px solid color("echo");
    border-radius: 15px;
    height: fit-content;
    height: 0;
    visibility: hidden;
    pointer-events: none;
    flex-shrink: 0;
    transition: 1s border-color ease-out;

    &,
    .progress-fill {
        border-radius: 15px;
    }

    &.show {
        height: auto;
        visibility: visible;
        margin-bottom: 40px;
    }

    p {
        position: relative;
        font-size: 0.625rem;
        letter-spacing: 0.025em;
        z-index: 2;
    }

    .progress-fill {
        position: absolute;
        height: 100%;
        background: color("echo");
        top: 0;
        left: 0;
        z-index: 1;
        transition: 1s all ease-out;
    }

    &.failed {
        border-color: color("tango");

        .progress-fill {
            background: color("tango");
        }
    }
}