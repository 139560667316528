.search {
    .search-query {
        font-size: rem-calc(20px);
    }

    .result {
        cursor: pointer;
        
        &:hover {
            color: color("echo");
        }
    }
}