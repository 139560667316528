#login-screen {
    background: var(--bg) left center/65% auto no-repeat;

    &,
    .overlay,
    .login-wrapper {
       height: 100vh;
    }

    .overlay {
        background: linear-gradient(to top left, var(--mantine-color-body), transparent);
    }

    .login-wrapper {
        width: 35%;
        min-width: 450px;
        background: linear-gradient(to top left, var(--mantine-color-body) 50%, rgba(var(--mantine-color-body), 93%));

        .header {
            img {
                height: 30px;
            }
        }

        h1 {
            font-weight: 700;
        }
    }
}