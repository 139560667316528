#statistics-wrapper {
    gap: 20px;

    .statistic {
        width: calc(25% - 15px);
        max-width: 267px;

        .label {
            font-size: clamp(rem-calc(12px), 1.398vw, rem-calc(20px));
        }

        .result {
            font-weight: 300;

            .trend-wrapper {
                .up-trend {
                    top: 16%;
                }

                .down-trend {
                    bottom: 21%;
                }

                @media screen and (max-width: 991px) {
                    img {
                        width: 10px;
                    }
                }
            }
        }

        @media screen and (max-width: 991px) {
            width: calc(50% - 10px);
        }
    }
}

.chart-wrapper {
    height: 368px;

    @media screen and (max-width: 991px) {
        height: 300px;
    }
}

.two-col-charts {
    gap: 15px;

    p {
        width: 55%;
    }

    @media screen and (min-width: 768px) {
        &>div {
            width: calc(50% - 7.5px);
        }
    }
}

.primary-banner {
    display: flex;
    align-items: center;
    padding: calc(var(--mantine-spacing-xl) * 2);
    border-radius: var(--mantine-radius-md);
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
    border: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-8));

    .banner-body {
        padding-right: calc(var(--mantine-spacing-xl) * 4);

        .banner-title {
            color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
            font-family:
                Greycliff CF,
                var(--mantine-font-family);
            line-height: 1;
            margin-bottom: var(--mantine-spacing-md);
        }

        .banner-controls {
            display: flex;
            margin-top: var(--mantine-spacing-xl);
        }
    }    
}