.documents {
    .file-upload-wrapper {
        #file-upload {
            border: 1px dashed color("sierra");
            border-radius: 10px;

            p {
                font-size: clamp(rem-calc(24px), 2.096vw, rem-calc(30px));
                line-height: 1.3;
            }
        }

        p {
            margin: 0;
        }

        .file {
            background: rgba(color("bravo"), 70%);

            .card-header {
                box-shadow: 0 3px 5px rgba(color("black"), 11%);
                
                .filename {
                    font-size: clamp(rem-calc(14px), 1.398vw, rem-calc(20px));
                    line-height: 1.5;
                    letter-spacing: 0.025em;
    
                    img {
                        width: 16.44px;
                        height: 14.99px;
    
                        @include media-breakpoint-down(md) {
                            width: 12px;
                        }
                    }
    
                    &,
                    img {
                        opacity: 0.3;
                    }
    
                    &.complete,
                    & img,
                    &.failed {
                        opacity: 1;
                    }
    
                    &.failed {
                        color: color("tango");
                    }
    
                    #formFilename {
                        padding-left: 57px;
                    }
                }

                button {
                    padding: 0;
                    background: none;
                    border: none;
                    height: fit-content;
    
                    &:disabled {
                        opacity: 0.3;
                    }
                }
            }

            .break {
                width: 100%;
                height: 0;
            }
        }
    }

    .card {
        .message {
            min-height: unset;
        }

        #formEditFilename::selection {
            user-select: none;
        }

        .options-wrapper {
            .quinary-btn {
                @extend .tif-font-sm;
            }
        }

        .edit-bot {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .secondary-form {
        h4 {
            font-size: rem-calc(20px);
            line-height: 1.3;
        }
    }
}