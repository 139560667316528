.editable-field-wrapper {
    width: fit-content;

    &:hover {
        &::after {
            content: "";
            position: absolute;
            width: 61px;
            height: 100%;
            left: 100%;
            z-index: 1;
        }
    }

    .editable-field {
        border: none;
        padding: 0;
        margin: 0;
        font-size: var(--input-font-size);
        height: var(--input-height);
        outline: none !important;
    }

    .mantine-Input-input {
        width: 192px;
    }

    .edit-btns {
        left: calc(100% + 10px);
        z-index: 2;
    }
}