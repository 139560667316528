html {
    &[data-mantine-color-scheme="dark"] {
        #clients {
            .search-input {
                --input-bg: var(--mantine-color-dark-5);
            }

            .mantine-Table-td {
                &:hover {
                    background-color: var(--mantine-color-dark-8);
                }
            }

            .indicator {
                background-color: var(--mantine-color-dark-6);
                border-color: var(--mantine-color-dark-4);
            }

            .tab {
                color: var(--mantine-color-dark-1);

                &[data-active] {
                    color: var(--mantine-color-white);
                }
            }
        }
    }

    &[data-mantine-color-scheme="light"] {
        #clients {
            .search-input {
                --input-bg: var(--mantine-color-gray-2);
            }

            .mantine-Table-td {
                &:hover {
                    background-color: var(--mantine-color-gray-2);
                }
            }
        }
    }
}

.list {
    position: relative;
}

.indicator {
    background-color: var(--mantine-color-white);
    border-radius: var(--mantine-radius-md);
    border: 1px solid var(--mantine-color-gray-2);
    box-shadow: var(--mantine-shadow-sm);
}

.tab {
    z-index: 2 !important;
    font-weight: 500;
    transition: color 100ms ease;
    color: var(--mantine-color-gray-7);

    &[data-active] {
        color: var(--mantine-color-black);
    }
}

.client-image-wrapper {
    width: fit-content;

    button {
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        button {
            opacity: 1;
        }
    }
}