
.data-submenu {
    list-style: none;
    display: flex;
    padding: 0 15px;
    width: 100%;
    background-color: color("delta");
    border-bottom: 1px solid color("bravo");
    height: 60px;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 992px) {
        padding: 0 40px;
        justify-content: flex-start;
    }

    li {
        display: block;
        color: color("bravo");
        width: auto;
        margin-right: 15px;
        font-size: 16px;
        line-height: 1.5;
        cursor: pointer;
        
        @media (min-width: 992px) {
            margin-right: 30px;
            width: 100px;
        }

        &.active {
        color: color("white");
        }

        &:last-child {
        margin-right: 0;
        }

    }
}

.light-mode {
    .data-submenu {
        background-color: color("white");
        border-bottom: 1px solid color("quebec");

        li {
            color: color("oscar");

            &.active {
                color: color("november");
            }
        }
    }
}
