html {
    &[data-mantine-color-scheme="light"] {
        .user-settings-tabs,
        .client-settings-tabs {
            background: var(--mantine-color-gray-1);
        }
    }

    &[data-mantine-color-scheme="dark"] {
        .user-settings-tabs,
        .client-settings-tabs {
            background: var(--mantine-color-dark-8);
        }
    }
}

.user-settings-modal,
.client-settings-modal,
.clients-settings-modal {
    --modal-content-max-height: 90dvh;
    
    .user-settings-tabs,
    .client-settings-tabs {
        width: 200px;
        box-shadow: inset -1px 0 0 light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
        min-height: 440px;
        max-height: var(--modal-content-max-height, calc(100dvh - var(--modal-y-offset) * 2));

        .avatar-wrapper {
            padding: 16px 0 0 16px;
        }

        .link {
            display: block;
            text-decoration: none;
            color: var(--mantine-color-text);
            line-height: 1.2;
            font-size: var(--mantine-font-size-sm);
            padding: var(--mantine-spacing-xs);
            border-top-left-radius: var(--mantine-radius-sm);
            border-bottom-left-radius: var(--mantine-radius-sm);
            cursor: pointer;

            &.dark {
                background-color: var(--mantine-color-dark-9) !important;
                border-right: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
            }
    
            &:hover {
                background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6)) !important;
                border-right: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
            }

            &.sublink {
                &::before {
                    content: "";
                    position: absolute;
                    left: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 8px;
                    height: 1px;
                    background: light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
                }
            }

            &.linkActive {
                font-weight: 500;
                border-right: 1px solid light-dark(var(--mantine-color-blue-6), var(--mantine-color-blue-4));
                color: light-dark(var(--mantine-color-blue-6), var(--mantine-color-blue-4));
        
                &,
                &:hover {
                    background-color: var(--mantine-color-blue-light) !important;
                }

                &.sublink {
                    &::before {
                        background: var(--mantine-color-blue-6);
                    }
                }
            }
        }
    }

    .date-total-hours {
        p {
            line-height: 2.25;
        }
    }

    .profile-image-upload {
        height: 100px;
    }
}